import React from 'react'

const Medsos = ({colorMedsos, fontMedsos, linkMedsos,contentMedsos}) => {
  return (
    <div className="col-lg-4 col-md-5 col-sm-6 shadow bg-white p-3 rounded mx-1 mb-2 medsos text-black">
              <h1 className='text-center ' style={{color: colorMedsos}}>
              <i className={`bi ${fontMedsos}`}></i>
              </h1>
              <p className='text-center fs-6'>
                <a  target='_blank' href={linkMedsos} rel="noopener noreferrer">{contentMedsos}</a>    
              </p>
    </div>
  )
}

export default Medsos