import React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Image from 'next/image';

const SlickPartner = ({dataPartner}) => {

 
    
    const settings = {
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              initialSlide: 2,
              dots: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              dots: true
            }
          }
        ]
      };
  return (
    <div className="container">
          <div className="row text-black">
            <div className="col-md-12">
              <h2 className='text-center'>PARTNER</h2>
              <p className='text-center'>
                Kami bekerjasama dengan beberapa instansi lain
              </p>
            </div>

            <div className="col-md-12">
            <div>
        <Slider {...settings}>
        

        {dataPartner.map((p,index) => (

            <div key={p._id}>
            <Image src={`${p.pictureRelasi}`} width="175" height="175" alt=""/>
            </div>
            ))}
          
        </Slider>
      </div>
            </div>
          </div>
    </div>
  )
}

export default SlickPartner