import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import ProductCarousel from '../components/big/ProductCarousel'
import SlickPartner from '../components/big/SlickPartner'
import Layout from '../components/Layout'
import Medsos from '../components/little/Medsos'

import Carousel from '../components/big/Carousel'
import CarouselMobile from '../components/big/CarouselMobile'
import { convertDocToObj, getAllProductData, getDataTitleById, getRelasisByJenis } from '../utils/serverSideFetch'
import Contacts from '../models/contactModel'
import { useState } from 'react'
import { sortedString } from '../utils/sorted'
import Banners from '../models/bennerModels'
import SelectSearch from 'react-select-search'
import 'react-select-search/style.css'
import { useRef } from "react";
import connectDB from '../utils/connectDB'


export default function Home({titleData,productData,dataOrmas,dataPartner,contactName,contactNameSpbu, bannerDataPc, bannerDataMobile,contactFirst}) {

  
  const searchInput = useRef();
  const [selectContact, setselectContact] = useState(0);
const [selectContactFull, setselectContactFull] = useState(contactName[selectContact]);
const [Index, setIndex] = useState(0);
const [tipeTampilContact,settipeTampilContact] = useState('depo');


let options;
if(tipeTampilContact == 'depo') {
  let contactDepo = [];

contactName.map((con, index) => (
  contactDepo.push({name : con.name, value : index})
   ))

options = [
  {
    type: "group",
    name: "DEPO",
    items: [
      ...contactDepo
    ]
  }
];


} else if (tipeTampilContact == 'spbu') {
  let contactSpbu = [];

  contactNameSpbu.map((con, index) => (
    contactSpbu.push({name : con.name, value : index})
    ))

  options = [
    {
      type: "group",
      name: "SPBU",
      items: [
        ...contactSpbu
      ]
    }
  ];

  
}





const handleFilter = (items) => {
  return (searchValue) => {
    if (searchValue.length === 0) {
      return options;
    }
    const updatedItems = items.map((list) => {
      const newItems = list.items.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });
      return { ...list, items: newItems };
    });
    return updatedItems;
  };
};



  
  return (
    <Layout titlePage={'Home'} titleData={titleData} dataOrmas={dataOrmas}>
      <Carousel bannerDataPc={bannerDataPc}/>
      <CarouselMobile bannerDataMobile={bannerDataMobile}/>
      <section id="content" className='pt-5 bg-white keAtas'>

        <div className="container ">
      <div className="row">
          <div className="col-md-6 pe-5 text-black">
            <h1>{titleData.judulSumber}</h1>
            <p>
            {titleData.deskripsiSumber}
    <Link href={'/#otherNav'}>
            <button className='btn btn-primary d-block mt-5'>PRODUK KAMI</button>
            </Link>
            </p>
          </div>
          <div className="col-md-6 mb-3" style={{ padding: 0}}>
            <Image src={titleData.imageSumber} className='img-fluid shadow' width={600} height={600} quality="100"/>
          </div>
      </div>
        </div>


        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#2780E3" fillOpacity="1" d="M0,0L48,21.3C96,43,192,85,288,128C384,171,480,213,576,197.3C672,181,768,107,864,106.7C960,107,1056,181,1152,208C1248,235,1344,213,1392,202.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
</svg>
      </section>

      <section id="otherNav" className='bg-primary overflow-hidden'>

        <div className="row px-5">
          <div className="col-md-12 container">
              <h2 className='text-white text-center'>
              Air Pegunungan Yang Membawa Kesegaran Alami...
              </h2>

          
          </div>

          <div className="col-md-12">

            <ProductCarousel productData={productData}/>

          </div>
          </div>





        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,224L40,218.7C80,213,160,203,240,186.7C320,171,400,149,480,128C560,107,640,85,720,101.3C800,117,880,171,960,197.3C1040,224,1120,224,1200,229.3C1280,235,1360,245,1400,250.7L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path></svg>
      </section>


      <section id="partner" className='bg-white overflow-hidden'>

        <SlickPartner dataPartner={dataPartner}/>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffff00" fillOpacity="1" d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
        
      </section>
      
      <section id="contact" className='bg-kuning pb-5 px-3'>
        
        <div className="container">
          
          <h1 className='text-center text-primary fs-1 mb-5'>Hubungi Kami (DEPO/SPBU)</h1>
          <div className={tipeTampilContact == "depo" ? `topnavActive badgebadge bg-kuning  me-3 d-inline col-2 text-white` : `topnav badgebadge bg-kuning  me-3 d-inline col-2 text-white`} onClick={async() => {
             settipeTampilContact("depo");
             setselectContact(0);
             setselectContactFull(contactName[selectContact]);
            }}>Depo AMDK</div> 
        <div className={tipeTampilContact == "spbu" ? `topnavActive badgebadge bg-kuning  me-3 d-inline col-2 text-white` : `topnav badgebadge bg-kuning  me-3 d-inline col-2 text-white`} onClick={async() => {
             settipeTampilContact("spbu");
             setselectContact(0);
             setselectContactFull(contactNameSpbu[selectContact]);
            }}>Agen AMDK (SPBU)</div>

          
          <div className="kontakContainer mb-3 shadow rounded p-5 text-black">
         
                <label htmlFor="formFile" className="form-label">Sesuaikan {tipeTampilContact} dengan daerah terdekat anda </label>
{/*                 
                    <select className="form-select" value={selectContact} onChange={e => {setselectContact(e.target.value)
                    setselectContactFull(contactName[e.target.value])
                    }}>
                        {contactName.map((con,index) => (
                        <option value={index} key={con._id}>{con.name} - {con.wa} {sortedString(con.alamat)}</option>
                        ))}
                    </select> */}

<SelectSearch 
  options={options}
  ref={searchInput}
  filterOptions={handleFilter}
  value={Index}
  name="DEPO"
  placeholder="Pilih Depo Dengan kota terdekat anda"
  search
  onChange={e => {
    if(tipeTampilContact == 'depo') {

      setselectContact(e)
      setselectContactFull(contactName[e])
      setIndex(e)
    } else {
      setselectContact(e)
      setselectContactFull(contactNameSpbu[e])
      setIndex(e)
    }
    
    }}/>


                    <h2 className='mt-3'>Alamat Lengkap</h2>

                    <p><i>{selectContactFull.alamat}</i></p>
                    {selectContactFull.linkMap && (

                    <iframe src={selectContactFull.linkMap} style={{width: '100%', height: '450px'}}  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    )}
                </div>

          <div className="row justify-content-center">

          
          {selectContactFull.wa && (
              <Medsos linkMedsos={`https://api.whatsapp.com/send?phone=${selectContactFull.wa}&text=${selectContactFull.pesanWa}`} fontMedsos="bi-whatsapp"  colorMedsos={'#075e54'} contentMedsos={selectContactFull.wa}/>
              )}

          {selectContactFull.ig && (
              <Medsos linkMedsos={`https://www.instagram.com/${selectContactFull.ig}/`} fontMedsos="bi-instagram"  colorMedsos={'#dd2a7b'} contentMedsos={selectContactFull.ig}/>
          )}
            

            

          {selectContactFull.fb && (
              <Medsos linkMedsos={'https://www.facebook.com/AirAlmasoem'} fontMedsos="bi-facebook"  colorMedsos={'#395693'} contentMedsos={selectContactFull.fb} /> 
          )}
          {selectContactFull.email && (
              <Medsos linkMedsos={`mailto:${selectContactFull.email}`} fontMedsos="bi-envelope"  colorMedsos={'#bb001b'} contentMedsos={selectContactFull.email} /> 
          )}

{selectContactFull.tiktok && (
  
  <Medsos linkMedsos={`https://www.tiktok.com/${selectContactFull.tiktok}`} fontMedsos="bi-tiktok"  colorMedsos={'#ff0050'} contentMedsos={selectContactFull.tiktok} />
  ) }
  {selectContactFull.twitter && (

<Medsos linkMedsos={`https://twitter.com/${selectContactFull.twitter}`} fontMedsos="bi-twitter"  colorMedsos={'#657786'} contentMedsos={selectContactFull.twitter} />
  ) }

          

            
          </div>

          <div className='row justify-content-center border-top pt-2 mt-2'>

            <h1 className='text-center text-primary'>Atau Pesan di</h1>


          <div className="col-lg-4 col-md-5 col-sm-6 shadow bg-white p-3 rounded mx-1 mb-2 medsos text-black">
                  <h1 className='text-center '>
                    <img src="/shopee.png" alt="" width={100} />
                  </h1>
                  <p className='text-center fs-6'>
                    <a  target='_blank' href={`https://shopee.co.id/${contactFirst.shopee}`} rel="noopener noreferrer">{contactFirst.shopee}</a>    
                  </p>
        </div>

        <div className="col-lg-4 col-md-5 col-sm-6 shadow bg-white p-3 rounded mx-1 mb-2 medsos text-black">
                  <h1 className='text-center '>
                    <img src="/tokped.png" alt="" width={100} />
                  </h1>
                  <p className='text-center fs-6'>
                    <a  target='_blank' href={`https://www.tokopedia.com/${contactFirst.tokopedia}`} rel="noopener noreferrer">{contactFirst.tokopedia}</a>    
                  </p>
        </div>

          </div>

        </div>
      </section>
    </Layout>
  )
}



export async function getStaticProps(context) {

 
  const productData = await getAllProductData();
  const dataOrmas = await getRelasisByJenis('ormas');
  const dataPartner = await getRelasisByJenis('partner');
  const titleData = await getDataTitleById('6301dbdb8e6ecc04f372e7fb');
  const contactName = await Contacts.find({tipe : 'depo'}).lean();
  const contactNameSpbu = await Contacts.find({tipe : 'spbu'}).lean();
  const bannerDataPc = await Banners.find({halamanBanner : "home",tipeBanner : "pc"}).lean();
  const bannerDataPcCount = await Banners.find({halamanBanner : "home",tipeBanner : "pc"}).count();
  const bannerDataMobile = await Banners.find({halamanBanner : "home",tipeBanner : "mobile"}).lean();
  const bannerDataMobileCount = await Banners.find({halamanBanner : "home",tipeBanner : "mobile"}).count();
  connectDB();
  const contactFirst = await Contacts.find({}).lean();


  



  



  return {
      props: {titleData : convertDocToObj(titleData),
      productData : productData.map(p => convertDocToObj(p)),
      dataOrmas : dataOrmas.map(o => convertDocToObj(o)),
      dataPartner : dataPartner.map(part => convertDocToObj(part)),
      contactName: contactName.map(cona => convertDocToObj(cona)),
      contactNameSpbu: contactNameSpbu.map(conas => convertDocToObj(conas)),
      bannerDataPc: bannerDataPc.map(bp => convertDocToObj(bp)), count: bannerDataPcCount,
      bannerDataMobile: bannerDataMobile.map(bm => convertDocToObj(bm)), count: bannerDataMobileCount,
      contactFirst: convertDocToObj(contactFirst[0])
    }, // will be passed to the page component as props

    revalidate: 30,
  }
}
